

















































































































































































































































































































































.manual-address {
  // Make the switch vertical
  .v-input__slot {
    flex-wrap: wrap;
    top: -15px;
    position: relative;

    > * {
      flex: 1 1 100%;
    }

    .v-input--selection-controls__input {
      order: 2;
    }
  }
}
